
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-react.scss?v=1.0.0";
import "assets/demo/demo.css";

import Index from "views/Index.jsx";
import ProfilePage from "./views/ProfilePage.jsx";
import WorkPage from "./views/WorkPage";
import ContactPage from "./views/ContactPage";
import {ParallaxProvider} from "react-scroll-parallax/cjs";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/home" render={props => <Index {...props} />} />
      <Route
        path="/projects"
        render={props => <ProfilePage {...props} />}
      />
      <Route
          path="/experience"
          render={props => <WorkPage {...props} />}
      />
      <Route
          path="/contact"
          render={props => <ContactPage {...props} />}
      />
      <Redirect from="/" to="/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
