import React from "react";

import ScrollTop from "../components/ScrollTop";
import Spinner from "../components/Spinner";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";


const carouselItems = [
    {
        src: require("assets/img/sahilteacher1.jpg"),
        altText: "Slide 2",
        caption: ""
    },
    {
        src: require("assets/img/sahilteacher2.jpg"),
        altText: "Slide 3",
        caption: ""
    }
];

const carousel2 = [
    {
        src: require("assets/img/TrendyIso.png"),
        altText: "Slide 1",
        caption: ""
    }
]

let ps = null;



class WorkPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tabs: 1,
            isLoading: true
        };
    }

    componentDidMount() {
        document.body.classList.toggle("profile-page")
        /*
        setTimeout(() => {
            this.setState({isLoading:false})
        }, 500);
         */
    }
    componentWillUnmount() {
        document.body.classList.toggle("profile-page");
    }
    toggleTabs = (e, stateName, index) => {
        e.preventDefault();
        this.setState({
            [stateName]: index
        });
    };

    loading = () => {
        if (this.state.isLoading) {
            return <Spinner/>
        }
        return
    }

    renderContent(){
        return (
            <>
                <ScrollTop/>
                <IndexNavbar />
                    <div className="page-header">
                        <img
                            alt="..."
                            className="background-display"
                            src={require("assets/img/nike.jpg")}
                        />
                        <Container className="align-items-center">
                            <Row>
                                <Col lg="6" md="6">
                                    <h1 className="profile-title text-left">Nike</h1>
                                    <h5 className="text-on-back">01</h5>
                                    <p className="profile-description-alt">
                                        RPA Engineering Consultant
                                        <br/>
                                        February 2020 - May 2020
                                    </p>
                                    <div className="btn-wrapper profile pt-3">
                                        <Button
                                            className="btn-link"
                                            color="warning"
                                            onClick={e => window.open("https://nike.com", "_blank")}
                                        >
                                            <i className="tim-icons icon-book-bookmark" /> Learn More
                                        </Button>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <Card>
                                        <CardHeader>
                                            <Button
                                                className="btn-link"
                                                color="warning"
                                            >
                                                <i className="tim-icons icon-double-right" /> What I Did
                                            </Button>
                                        </CardHeader>
                                        <CardBody>
                                            <p>
                                                During my time with Nike, I identified strategies to automate processing and tracking cases
                                                with the Trademark & Copyright team by looking into current Robotic
                                                Process Automation tools. I also got the opportunity to create an automated in-house legal workflow solution with Python, GSuite API, and Streak CRM API.
                                                <br />
                                                <br />
                                                Before pitching our strategic legal workflow recommendations to Nike, I directed focus groups and interviews with Nike stakeholders and conducted a survey of large companies
                                                who are tackling similar challenges to evaluate alternatives including custom-built software solutions.
                                                <br />
                                                <br />
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                <div className="page-header">
                    <img
                        alt="..."
                        className="background-display"
                        src={require("assets/img/zeni.png")}
                    />
                    <Container className="align-items-center">
                        <Row>
                            <Col lg="6" md="6">
                                <h1 className="profile-title text-left">Zeni</h1>
                                <h5 className="text-on-back">02</h5>
                                <p className="profile-description-alt">
                                    Software Engineering Intern
                                    <br/>
                                    June 2020 - August 2020
                                </p>
                                <div className="btn-wrapper profile pt-3">
                                    <Button
                                        className="btn-link"
                                        color="info"
                                        onClick={e => window.open("https://www.zeni.ai/", "_blank")}
                                    >
                                        <i className="tim-icons icon-book-bookmark" /> Learn More
                                    </Button>
                                </div>
                            </Col>
                            <Col md="6">
                                <Card>
                                    <CardHeader>
                                        <Button
                                            className="btn-link"
                                            color="info"
                                        >
                                            <i className="tim-icons icon-double-right" /> What I Did
                                        </Button>
                                    </CardHeader>
                                    <CardBody>
                                        <p>
                                            While working for Zeni I implemented a secure role-based access control system from the ground up to alter visible data
                                            on a user's financial dashboard given their role (Admin, Investor, Banker, etc), resulting in four new user roles being added to the platform.
                                            <br />
                                            <br />
                                            Additionally, I developed the Zeni Insights infrastructure, where given a set of data embedded in financial reports such as
                                            cash position and net burn reports, a meaningful insight can be produced via natural language generation and displayed on their dashboard.
                                            <br />
                                            <br />
                                            Worked with Python and the Google Cloud SDK and contributed role access control and insights APIs to the Zeni finance SDK.
                                        </p>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                    <div className="page-header">
                        <img
                            alt="..."
                            className="background-display"
                            src={require("assets/img/uc-berkeley.jpg")}
                        />
                        <Container className="align-items-center">
                            <Row>
                                <Col lg="6" md="6">
                                    <h1 className="profile-title text-left">CS61A Course Staff</h1>
                                    <h5 className="text-on-back">03</h5>
                                    <p className="profile-description-alt">
                                        Tutor
                                        <br/>
                                        August 2020 - Present
                                    </p>
                                    <div className="btn-wrapper profile pt-3">
                                        <Button
                                            className="btn-link"
                                            color="success"
                                            onClick={e => window.open("https://cs61a.org/", "_blank")}
                                        >
                                            <i className="tim-icons icon-book-bookmark" /> Learn More
                                        </Button>
                                    </div>
                                </Col>
                                <Col md="6">
                                    <Card>
                                        <CardHeader>
                                            <Button
                                                className="btn-link"
                                                color="success"
                                            >
                                                <i className="tim-icons icon-double-right" /> What I Did
                                            </Button>
                                        </CardHeader>
                                        <CardBody>
                                            <p>
                                                I lead my own weekly small group tutorial sections for students in
                                                CS61A: The Structure and Interpretation of Computer Programs which is the largest class at Berkeley with 2000 students.
                                                <br />
                                                <br />
                                                I teach students on concepts like data structures, object-oriented programming, recursion, and runtime (Python, SQL, Scheme).
                                                Additionally, I facilitate course advising sections, office hours, active Piazza threads, content review, grading, and lab/project parties.
                                            </p>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Footer/>
            </>
        );

    }

    render() {
        return (
            <div className="wrapper">
                {this.renderContent()}
            </div>
            /*
          <div className="wrapper">
              {this.state.isLoading ? this.loading() : this.renderContent()}
          </div>

             */
        );
    }
}

export default WorkPage;