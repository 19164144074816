import React from "react";
import 'react-spinners-css';
import {Ripple} from "react-spinners-css";
import "../assets/css/mystyle.css"


class Spinner extends React.Component {
    constructor(props){
        super(props)
    }

   render() {
       return (
           <div className="ripple-load">
                <Ripple className="ripple-load" color="#0000000"/>
           </div>
       );
   }
}

export default Spinner;