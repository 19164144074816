
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container>
          <Row>
            <Col md="3">
              <h2 className="title center">Sahil Morchi</h2>
            </Col>
            <Col md="3">
              <Nav>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/projects" tag={Link}>
                    Projects
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/experience" tag={Link}>
                    Work Experience
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/contact" tag={Link}>
                    Contact
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
            <Col md="3">
            </Col>
            <Col md="3">
              <h3 className="title">Connect with me:</h3>
              <div className="btn-wrapper profile">
                <Button
                  className="btn-icon btn-neutral btn-round btn-simple"
                  color="default"
                  href="https://linkedin.com/in/sahilmorchi"
                  id="tooltip622135962"
                  target="_blank"
                >
                  <i className="fab fa-linkedin" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  Connect
                </UncontrolledTooltip>
                <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://github.com/sahilmorchi"
                    id="tooltip622135962"
                    target="_blank"
                >
                  <i className="fab fa-github" />
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip622135962">
                  See my work
                </UncontrolledTooltip>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    );
  }
}

export default Footer;
