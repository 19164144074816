import React from "react";

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardImg,
    CardTitle,
    Label,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col
} from "reactstrap";

import Navbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import ScrollTop from "../components/ScrollTop";

class ContactPage extends React.Component {
    state = {
        squares1to6: "",
        squares7and8: ""
    };
    componentDidMount() {
        document.body.classList.toggle("register-page");
        document.documentElement.addEventListener("mousemove", this.followCursor);
    }
    componentWillUnmount() {
        document.body.classList.toggle("register-page");
        document.documentElement.removeEventListener(
            "mousemove",
            this.followCursor
        );
    }
    followCursor = event => {
        let posX = event.clientX - window.innerWidth / 2;
        let posY = event.clientY - window.innerWidth / 6;
        this.setState({
            squares1to6:
                "perspective(500px) rotateY(" +
                posX * 0.05 +
                "deg) rotateX(" +
                posY * -0.05 +
                "deg)",
            squares7and8:
                "perspective(500px) rotateY(" +
                posX * 0.02 +
                "deg) rotateX(" +
                posY * -0.02 +
                "deg)"
        });
    };
    render() {
        return (
            <>
                <ScrollTop/>
                <Navbar />
                <div className="wrapper">
                    <div className="page-header">
                            <Container>
                                <div className="content-center sizer">
                                    sahil.morchi@gmail.com
                                </div>
                                <Row>
                                    <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                                        <div
                                            className="square square-7"
                                            id="square7"
                                            style={{ transform: this.state.squares7and8 }}
                                        />
                                        <div
                                            className="square square-8"
                                            id="square8"
                                            style={{ transform: this.state.squares7and8 }}
                                        />
                                    </Col>
                                </Row>
                                <div className="register-bg" />
                                <div
                                    className="square square-1"
                                    id="square1"
                                    style={{ transform: this.state.squares1to6 }}
                                />
                                <div
                                    className="square square-2"
                                    id="square2"
                                    style={{ transform: this.state.squares1to6 }}
                                />
                                <div
                                    className="square square-3"
                                    id="square3"
                                    style={{ transform: this.state.squares1to6 }}
                                />
                                <div
                                    className="square square-4"
                                    id="square4"
                                    style={{ transform: this.state.squares1to6 }}
                                />
                                <div
                                    className="square square-5"
                                    id="square5"
                                    style={{ transform: this.state.squares1to6 }}
                                />
                                <div
                                    className="square square-6"
                                    id="square6"
                                    style={{ transform: this.state.squares1to6 }}
                                />
                            </Container>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

export default ContactPage;
