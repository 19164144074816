
import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import {
  UncontrolledCarousel,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";


class Biography extends React.Component {
  state = {};
  render() {
    return (
      <div className="section section-signup">
        <Container>
          <div className="squares square-1" />
          <div className="squares square-2" />
          <div className="squares square-3" />
          <div className="squares square-4" />
          <Row className="row-grid justify-content-between align-items-center">
            <Col lg="6">
              <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
              <h3 className="display-3 text-white">
                Hi There!{" "}
              </h3>
              <h3 className="display-3 text-white">
                I am a Developer and Designer based out of Berkeley, CA.
              </h3>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
              <h4 className="text-white mb-3">
                My name is Sahil Morchi and I am currently a Computer Science student at the University of California, Berkeley.
              </h4>
              <h4 className="text-white mb-3">
                My experience lies in going through the entire product development pipeline and building beautiful, yet functional apps.
                I have always had an entrepreneurial spirit and love tackling new problems and projects as they come at me!
              </h4>
              <br/>
              <div className="btn-wrapper">
                <Button color="primary" to="projects" tag={Link} to="/projects">
                  View Projects
                </Button>
              </div>
              </ScrollAnimation>
            </Col>
            <Col className="mb-lg-auto" lg="6">
              <small className="d-block text-uppercase font-weight-bold mb-4">
              </small>
              <img
                  alt="..."
                  className="img-fluid rounded shadow-lg"
                  src={require("assets/img/SahilMorchi.jpg")}
              />
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Biography;
