
import React from "react";

import ScrollTop from "../components/ScrollTop";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Label,
  FormGroup,
  Form,
  Input,
  FormText,
  NavItem,
  NavLink,
  Nav,
  Table,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  UncontrolledCarousel
} from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";

const carouselItems = [
  {
    src: require("assets/img/sahilteacher1.jpg"),
    altText: "Slide 2",
    caption: ""
  },
  {
    src: require("assets/img/sahilteacher2.jpg"),
    altText: "Slide 3",
    caption: ""
  }
];

const carousel2 = [
  {
    src: require("assets/img/TrendyIso.png"),
    altText: "Slide 1",
    caption: ""
  }
]

const songwaveCarousel = [
  {
    src: require("assets/img/songwavehome.png"),
    altText: "Songwave Login",
    caption: ""
  },
  {
    src: require("assets/img/dashboard.png"),
    altText: "Dashboard",
    caption: ""
  },
  {
    src: require("assets/img/visualizer.png"),
    altText: "Visualizer",
    caption: ""
  },
  {
    src: require("assets/img/webplayback.png"),
    altText: "Web Playback",
    caption: ""
  }
]

let ps = null;



class ProfilePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tabs: 1
    };
  }

  componentDidMount() {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++) {
        ps = new PerfectScrollbar(tables[i]);
      }
    }

     */
    document.body.classList.toggle("profile-page");
  }
  componentWillUnmount() {
    /*
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }

     */
    document.body.classList.toggle("profile-page");
  }
  toggleTabs = (e, stateName, index) => {
    e.preventDefault();
    this.setState({
      [stateName]: index
    });
  };


  render() {
    return (
      <>
        <ScrollTop/>
        <IndexNavbar />
        <div className="wrapper">
          <div className="page-header">
            <img
                alt="..."
                className="background-display"
                src={require("assets/img/songwaveiso.png")}
            />
            <Container>
              <Row className="justify-content-between">
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">Songwave</h1>
                  <h5 className="text-on-back">01</h5>
                  <p className="profile-description text-left">
                    My love for music and Spotify led me to create the ultimate Spotify companion app, including features like realtime audio visualization, streaming analysis, and insights.
                  </p>
                  <div className="btn-wrapper pt-3">
                    <Button
                        className="btn-link"
                        style={{color: "#ac84fb"}}
                        onClick={e => window.open("https://songwave.live", "_blank")}
                    >
                      <i className="tim-icons icon-book-bookmark" /> Learn More
                    </Button>
                  </div>
                </Col>
                <Col md="6">
                  <Row className="justify-content-between align-items-center">
                    <UncontrolledCarousel items={songwaveCarousel} />
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>

          <div className="page-header">
            <img
                alt="..."
                className="background-display"
                src={require("assets/img/CodeSV.png")}
            />
            <Container className="align-items-center">
              <Row className="justify-content-between">
                <Col md="6">
                  <Row className="justify-content-between align-items-center">
                    <UncontrolledCarousel items={carouselItems} />
                  </Row>
                </Col>
                <Col md="5">
                  <h1 className="profile-title text-left">Code SV</h1>
                  <h5 className="text-on-back">02</h5>
                  <p className="profile-description">
                    A registered nonprofit I started to bridge the gap between
                    low-income students and computer science. Sponsored by Firebase and Silicon Valley Education Foundation.
                  </p>
                  <div className="btn-wrapper profile pt-3">
                    <Button
                        className="btn-link"
                        color="success"
                        //href="http://codesv.org"
                        onClick={e => window.open("http://codesv.org", "_blank")}
                    >
                      <i className="tim-icons icon-book-bookmark" /> Learn More
                    </Button>
                  </div>
                </Col>

              </Row>
            </Container>
          </div>

          <div className="page-header">
              <img
                  alt="..."
                  className="background-display"
                  src={require("assets/img/TrendyIso.png")}
              />
            <Container>
              <Row className="justify-content-between">
                <Col lg="6" md="6">
                  <h1 className="profile-title text-left">Trendy</h1>
                  <h5 className="text-on-back">03</h5>
                  <p className="profile-description text-left">
                    Developed a song recommendation engine that processes trends
                    based on audio features such danceability and energy within a user’s playlists in order to recommend a new playlist that best fits the user’s current music taste.
                  </p>
                  <div className="btn-wrapper pt-3">
                    <Button
                      className="btn-link"
                      color="danger"
                      //href="https://github.com/sahilmorchi/Trendy"
                      onClick={e => window.open("https://github.com/sahilmorchi/Trendy", "_blank")}
                    >
                      <i className="tim-icons icon-book-bookmark" /> Learn More
                    </Button>
                  </div>
                </Col>
                <Col md="6">
                  <Row className="justify-content-between align-items-center">
                    <UncontrolledCarousel items={carousel2} />
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
          <Footer/>
        </div>
      </>
    );
  }
}

export default ProfilePage;
