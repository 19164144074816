
import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";


class Navbars extends React.Component {
  render() {
    return (
      <div className="section section-navbars">
        <img alt="..." className="path" src={require("assets/img/path3.png")} />

        <div id="navbar">
          <div className="navigation-example">
            {/* Navbar Info */}
            <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
              <Container id="menu-dropdown">
                <h1 className="title mb-3">Skills</h1>
              </Container>
            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="primary">
                      Python / Numpy / SciKit Learn
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active" color ="primary">
                      <Button className="btn-link" color="primary">
                        Machine Learning
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            {/* End Navbar Info */}

            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="" color="info">
                    <Button className="btn" color="primary">
                      Java
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="primary">
                        Desktop Applications
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>

            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="primary">
                      SQL
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="primary">
                        Databases
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>

            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="primary">
                      Swift
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="primary">
                        iOS Apps
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="info">
                      HTML5 / CSS3 / ES5
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="info">
                        Standard Web Development
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>

            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="info">
                      React
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="info">
                        Web App Framework
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>

            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="info">
                      Firebase
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="info">
                        Hosting / Backend
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            </ScrollAnimation>

            <ScrollAnimation animateIn='fadeIn' animateOnce='true'>
            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="warning">
                      Adobe XD / Sketch
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="warning">
                        Wireframing
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>

            <Navbar className="bg-transparent" expand="lg">
              <Container>
                <div className="navbar-transparent">
                  <NavbarBrand href="">
                    <Button className="btn" color="warning">
                      Adobe Photoshop / Illustrator
                    </Button>
                  </NavbarBrand>
                </div>
                <Collapse navbar isOpen={false}>
                  <Nav className="ml-auto" navbar>
                    <NavItem className="active">
                      <Button className="btn-link" color="warning">
                        Design
                      </Button>
                    </NavItem>
                  </Nav>
                </Collapse>
              </Container>
            </Navbar>
            </ScrollAnimation>

          </div>
        </div>
      </div>
    );
  }
}

export default Navbars;
